@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Inter&family=Lato&family=Montserrat&family=Pacifico&display=swap");

:root {
  --primary: #3e79f7 !important;
  --primary-two: #0000fb44 !important;
  --primary-three: #5353fba1 !important;
  --text: #72849a !important;
  --active-list: #455560 !important;
  --danger-alt: #ff6b72 !important;
  --secondary: #356396 !important;
  --light: #3e79f718 !important;
  --accent: #5353fba1 !important;
}

/* custom modifaed theme */

.scrollbar_smooth{overflow-y: scroll;overflow-x: hidden;scroll-behavior: smooth;}
.scrollbar_smooth::-webkit-scrollbar{width: 6px;background-color: transparent;}
.scrollbar_smooth::-webkit-scrollbar-thumb{width: 100%;background-color: var(--primary);border-radius: 5px;}
.scrollbar_smooth::-webkit-scrollbar-corner{background-color: transparent;color: transparent;}

.bg-custom-1 {
  background-color: #eaf4fe !important;
  transition: all 0.3s ease;
}
.bg-primary {
  background-color: var(--primary);
}
.ant-btn-primary {
  background-color: var(--primary);
}
.ant-btn-primary:not(:disabled):hover {
  background-color: var(--secondary);
}
.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: var(--primary);
}
.ant-menu-light
  .ant-menu-item:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ),
.ant-menu-light
  .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  color: var(--primary);
}
.ant-menu-light .ant-menu-item-selected {
  color: var(--primary);
}
.ant-menu-light .ant-menu-item-selected {
  background-color: var(--light);
}
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
  background-color: var(--light);
}
.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: var(--light);
}
.ant-menu-light.ant-menu-inline .ant-menu-item-selected::after {
  border-color: var(--primary);
}
.ant-spin .ant-spin-dot-item {
  background-color: var(--primary) !important;
}
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--light);
}
.ant-select-focused .ant-select-selector,
.ant-select-open.ant-select-show-search .ant-select-selector {
  border: 1px solid var(--primary) !important;
}
.ant-input-affix-wrapper-focused,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: var(--primary) !important;
}
h5.input_img_text > span.text-info {
  color: var(--primary) !important;
}
.ant-spin {
  color: var(--primary);
}
.border-button:hover,
.edit-button:hover {
  color: var(--primary) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: var(--primary) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--primary);
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--primary);
}
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: var(--primary) !important;
}
.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-picker-focused,
.ant-picker:hover,
.ant-select:hover,
.ant-select:focus,
.ant-select.ant-select-in-form-item.ant-select-multiple.ant-select-show-arrow.ant-select-open.ant-select-show-search:hover,
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: var(--primary) !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input:focus,
.ant-input-focused {
  box-shadow: 0 0 0 2px var(--light);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--primary);
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: var(--primary);
}
.ant-checkbox-checked:after {
  border-color: var(--primary);
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > td,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: var(--light);
}
.ant-breadcrumb a:hover {
  color: var(--primary);
}
.ant-pagination .ant-pagination-item-active {
  background-color: var(--primary);
}
.ant-radio-wrapper:hover .ant-radio-wrapper,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio-wrapper .ant-radio-checked::after {
  border-color: var(--primary) !important;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-picker-dropdown .ant-picker-today-btn {
  color: var(--primary);
}
.ant-picker-dropdown .ant-picker-header-view button:hover,
.ant-picker-dropdown .ant-picker-today-btn:hover {
  color: var(--hover);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: var(--primary);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background-color: var(--primary);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: var(--primary) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--accent);
}
.ant-picker-focused,
.ant-picker:hover {
  border-color: var(--primary);
}
.ant-picker-now-btn {
  color: var(--primary);
}

/* --------Global Css========> */

ul {
  padding: 0;
}
ul li {
  list-style-type: none;
}

input::placeholder {
  color: #b8b8b89e !important;
}
/* new class css */
.gap-1 {
  gap: 4px;
}
.gap-2 {
  gap: 8px;
}
.gap-3 {
  gap: 12px;
}
.gap-4 {
  gap: 16px;
}
.gap-5 {
  gap: 20px;
}

.left-0 {
  left: 0;
}
.top-0 {
  top: 0;
}
.shadow-sm {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  position: relative;
}
.show {
  display: flex;
}
.hide {
  visibility: hidden !important;
  opacity: 0;
  transition: all 0.3s ease;
}

.scrollbar_smooth.scrollbar_smooth_Y {
  overflow-y: scroll;
  overflow-x: hidden;
}
.scrollbar_smooth.scrollbar_smooth_x {
  overflow-y: hidden;
  overflow-x: scroll;
}

.scrollbar_smooth::-webkit-scrollbar {
  width: 8px;
}
.scrollbar_smooth::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--primary);
  border-radius: 5px;
}

/* ---------------------- */

.site_logo {
  object-fit: contain;
}

/* UPLOAD IMAGE COMPONENT */
.file_upload_sec {
  border: 1px dashed gray;
  height: 150px;
  cursor: pointer;
}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon,
.file_upload_sec .img_icon_right {
  width: 50px;
  height: 50px;
  display: flex;
}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon_right {
  filter: grayscale(1);
  opacity: 0.6;
}

.file_upload_sec .img_icon_left {
  right: -50px;
  transition: all 0.3s ease;
}
.file_upload_sec .img_icon_right {
  left: -50px;
  transition: all 0.3s ease;
}
.file_upload_sec:hover .img_icon_left {
  right: -20px;
  transform: rotate(-20deg);
  transition: all 0.3s ease;
}
.file_upload_sec:hover .img_icon_right {
  left: -20px;
  transform: rotate(20deg);
  transition: all 0.3s ease;
}

.file_upload_sec .img_icon {
  z-index: 1;
}
.file_upload_sec .img_icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.file_upload_sec .uploaded_img {
  object-fit: cover;
  object-position: center;
}

.file_upload_input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.img_delete_sec {
  top: 0;
  left: 0;
  background-color: rgba(174, 173, 173, 0.41);
}
.delete_btn {
  border: 1px solid #ff6b72;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ff6b72;
  transition: all 0.3s ease;
}
.delete_btn:hover {
  scale: 1.2;
  transition: all 0.3s ease;
}
/* =====================> */

.tasks_card {
  background-color: #fafafb;
  border-radius: 5px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  position: relative;
}
.tasks_card .img_sec {
  width: 45%;
  overflow: hidden;
  border-radius: 5px;
}
.tasks_card .list_sec {
  width: -webkit-fill-available;
}
.tasks_card .img_sec img {
  object-fit: cover;
  object-position: center;
}
.tasks_card .challenge_card > div {
  height: 136px;
}
.tasks_card .challenge_card .list_sec {
  overflow-y: scroll;
}
/* .tasks_card::before{content: '';width: 80px;height: 80px;position: absolute;bottom: -36px;right: -36px;border-radius: 50%;display: flex;background-color: var(--primary-three);}
.tasks_card::after{content: '';width: 90px;height: 90px;position: absolute;bottom: -36px;right: -36px;border-radius: 50%;display: flex;background-color: var(--primary-two);z-index: 1;} */

.tasks_card .checkbox {
  border: 2px solid transparent;
  width: 18px;
  height: 18px;
  border-radius: 3px;
}
.tasks_card .checkbox.checked {
  background-color: var(--primary);
}
.tasks_card .checkbox:not(.checked) {
  border-color: var(--primary);
  background-color: transparent;
}
.tasks_card .checkbox > span {
  scale: 0.8;
}
.tasks_card li .challenge_name {
  color: var(--text);
}
.tasks_card .challenge_card_heading {
  color: var(--primary);
}
.quill > .ql-container {
  border-radius: 0 0 10px 10px;
  height: 200px;
  max-height: 250px;
}

/* <---------media box------> */
.media_box {
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  margin: 10px;
}
.media_box img {
  object-fit: cover;
  object-position: center;
}
.media_box.pdf_sec .icon img {
  object-fit: contain;
  object-position: center;
}
.media_box .name_sec {
  background-color: var(--primary);
  color: white;
  overflow: hidden;
  white-space: nowrap;
}
.media_box .name {
  overflow: hidden;
}

/* select custom components */

.select_custom {
  position: relative;
}
.select_custom .list_sec {
  position: absolute;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  margin-top: 5px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  z-index: 2;
  max-height: 240px;
  overflow-y: scroll;
}
.select_custom .list_sec::-webkit-scrollbar {
  width: 8px;
}
.select_custom .list_sec::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--primary);
  border-radius: 5px;
}
.select_custom .list {
  cursor: pointer;
  background-color: transparent;
  margin: 4px 0;
}
.select_custom .list:hover {
  background-color: #fafafb;
}
.select_custom .checked_icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.select_custom .dropdown .selected {
  background-color: #95b7ff13;
}

/* ----delete popup---- */

.delete_popup {
  background-color: rgba(0, 0, 0, 0.255);
  z-index: 1000;
}
.delete_popup .popup {
  border-radius: 5px;
  width: 300px;
}
.delete_popup .popup .dialogue {
  line-height: 20px;
}
.delete_popup .popup .del_btn {
  background-color: var(--danger-alt);
}
.delete_popup .popup .del_btn:focus-visible {
  outline: 4px solid #ff848ab1;
}
.delete_popup .popup .del_btn:hover {
  border: 1px solid #ff757c !important;
}

/* full screen loading-----> */
.full_screen_loading {
  z-index: 1000;
}

/* coach_profile_userlist */

.coach_profile_userlist {
  height: 325px;
}

/* media library */

.media_container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.card-box {
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  background-color: #fff;
}

.file-man-box {
  overflow: hidden;
  padding: 10px;
  border: 1px solid #e3eaef;
  border-radius: 5px;
  position: relative;
  height: 144px;
  max-width: 144px;
  min-width: 144px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.file-man-box .file-img-box {
  text-align: center;
}
.file-man-box .file-img-box img {
  height: 64px;
}

.file-man-box:hover {
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow: hidden;
}
.file-man-box h5 {
  font-size: 13px;
  margin-bottom: -8px;
}
.file-man-box.affirmation {
  padding: 0;
  overflow: hidden;
}
.file-man-box.affirmation .file-img-box,
.file-man-box.affirmation .file-img-box img {
  width: 100%;
  height: 65%;
}
.file-man-box.affirmation .file-img-box.image,
.file-man-box.affirmation .file-img-box.image img {
  width: 100%;
  height: 100%;
}
.file-man-box.affirmation .file-img-box img {
  object-fit: contain;
  object-position: center;
}
.file-man-box.affirmation .file-img-box.image img {
  object-fit: cover;
  object-position: center;
}

.file-man-box .action {
  background-color: #00000070;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.file-man-box:hover .action {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

.file-man-box .action > div {
  height: 35px;
  width: 35px;
  background-color: #fafafb;
  border-radius: 3px;
}
.file-man-box .action > div.del {
  background-color: var(--danger-alt);
  color: white;
}
.file-man-box .action > div:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}
.file-man-box .check_box {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: white;
}
.media_header .ant-upload-list {
  display: none;
}
.uploading_box {
  border: 1px dashed rgba(128, 128, 128, 0.585);
}
.uploading_text {
  color: gray;
  font-size: 12px;
}

/* view media */
.media_drawer .ant-drawer-body {
  padding: 12px !important;
}
.media_draw_box .media_draw_box_preview {
  width: 100%;
  overflow: hidden;
  height: 150px;
  border-radius: 5px;
}
.media_draw_box .media_draw_box_preview img {
  object-fit: cover;
  object-position: center;
}
.media_draw_box .details_sec table div {
  padding: 3px 0;
  color: gray;
}
.media_draw_box .details_sec table tr td:last-child div {
  padding-left: 30px;
  color: var(--primary);
}

.media_header_sec .check_box {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  background-color: white;
}

/* checkbox */

.check_box {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  top: 3px;
  right: 3px;
  background-color: white;
}
.check_box_alt {
  border: 2px solid #e6ebf1;
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  top: 3px;
  right: 3px;
  background-color: white;
}
.check_box_alt:hover {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  top: 3px;
  right: 3px;
  background-color: white;
}
.check_box_alt.active {
  background-color: var(--primary);
  border-color: var(--primary);
}

/* Query Search Filter */
.table-filter-list {
  padding: 7px 20px;
  border-radius: 10px;
}
.table-filter-list:hover {
  background-color: #f0f7ff;
}
.filter_list_sec .filter_list {
  max-height: 165px;
  overflow-y: scroll;
  border-bottom: 1px solid rgba(128, 128, 128, 0.311);
}
.filter_list_sec .filter_list::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.filter_list_sec .filter_list::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--primary);
  border-radius: 5px;
}
.filter_list_sec .filter_list::-webkit-scrollbar-corner {
  background-color: transparent;
  color: transparent;
}

/* Active Inactive button */

.activeBtn {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.activeBtn:hover {
  color: #08979c !important;
  background: #e6fffb !important;
  border-color: #87e8de !important;
}
.inActiveBtn {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.inActiveBtn:hover {
  background: #fff2e8 !important;
  color: #d4380d !important;
}

.multi-datepicker .rmdp-container,
.multi-datepicker .rmdp-container input {
  width: 100%;
}

/* player */
.audio-player {
  background-color: white;
  border-radius: 10px;
  width: 350px;
}
.skip_prev {
  transform: rotate(90deg);
}
.skip_next {
  transform: rotate(-90deg);
}
.skip_prev::before,
.skip_next::before {
  content: "10";
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 8px;
}
.skip_prev::before {
  transform: rotate(-90deg);
}
.skip_next::before {
  transform: rotate(90deg);
}
.skip_prev,
.skip_next,
.play_pause,
.next,
.prev {
  width: 50px;
  height: 50px;
  font-size: 25px;
  position: relative;
  background-color: #f3f5f9;
  border-radius: 50%;
  cursor: pointer;
}
.skip_prev,
.skip_next {
  width: 35px;
  height: 35px;
  font-size: 20px;
}
.progress_bar .ant-progress-outer * {
  height: 4px;
}
.progress_bar_sec {
  width: 100%;
  height: 15px;
}
.progress_bar_sec > span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.progress_bar_sec span.progress {
  top: 3px;
  opacity: 0;
}
.progress_bar .ant-progress-success-bg {
  height: 4px !important;
  cursor: pointer;
}
.progress_group {
  height: 12px;
}
.duration_left,
.total_duration {
  font-size: 10px;
}
.audio_name {
  background-color: #eaf4fe;
  border-radius: 5px;
}
.audio_name .name {
  width: 235px;
  overflow: hidden;
}
.audio_name .name > div {
  white-space: nowrap;
  font-weight: 600;
  font-size: 17px;
}
.audio_list .icon {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.audio_list .audio_item {
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.audio_list .audio_item.active {
  background-color: white !important;
}
.audio_list .audio_item .name {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.audio_list_sec {
  max-height: 230px;
}
.audio_list_sec .audio_item:hover .name > div {
  -moz-animation: marquee 15s linear infinite;
  -webkit-animation: marquee 15s linear infinite;
  animation: marquee 15s linear infinite;
}
.timer_duration {
  margin-top: 5px;
}
.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", sans-serif;
  content: "Roboto" !important;
}

/* .ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway", cursive;
  content: "Raleway" !important;
} */

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", sans-serif;
  content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", sans-serif;
  content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
  font-family: "Rubik", sans-serif;
  content: "Rubik" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Pacifico"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Pacifico"]::before {
  font-family: "Pacifico", cursive;
  content: "Pacifico" !important;
}

/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Montserrat {
  font-family: "Montserrat", sans-serif;
}
.ql-font-Lato {
  font-family: "Lato", sans-serif;
}
.ql-font-Rubik {
  font-family: "Rubik", sans-serif;
}
.ql-font-Inter {
  font-family: "Inter", sans-serif;
}
.ql-font-Pacifico {
  font-family: "Pacifico", cursive;
}

.trigger-scroll {
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 5px;
  background-color: var(--primary);
  justify-content: center;
  align-items: center;
}
.trigger-scroll a {
  color: white;
  width: 100%;
  height: 100%;
}

.form_label_certifications {
  position: relative;
  align-items: center;
  background: rgba(19, 139, 251, 0.05);
  border: 1px dashed rgb(13, 87, 245);
  border-radius: 12px;
  color: rgb(13, 87, 245);
  display: inline-flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  line-height: 23px;
  min-height: 60px;
  width: 100%;
  padding: 12px 24px;
}

#uploadFile {
  height: 1px;
  left: -999px;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 1px;
}
.preview {
  position: relative;
  align-items: center;
  max-width: 100%;
  height: 40px;
  color: #455560;
  font-size: 14px;
  margin-bottom: 0;
}

.media-selector {
  border: 1px dashed rgb(211, 211, 211);
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}
.media-selector input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
}
.media-selector .media {
  top: 0;
  left: 0;
}
.media-selector .media .thumbnai {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  border: 1px dashed rgb(211, 211, 211);
  overflow: hidden;
  padding: 2px;
}
.media-selector .media .thumbnai img{border-radius: 3px;}
.media-selector .filename {
  height: 50px;
  width: calc(97% - 50px);
  border-radius: 3px;
}
.media-selector .filename .name {
  width: calc(99% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgb(185, 185, 185);
}
.media-selector .close-btn {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: var(--danger-alt);
  color: white;
  font-size: 12px;
  z-index: 3;
}
.upload-media-modal .radio-item{border-radius: 5px;white-space: nowrap;background-color: var(--light);}